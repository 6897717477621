import "./categoryList.css";
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridExcelExportMenuItem,
} from "@mui/x-data-grid-premium";
import EditIcon from "@mui/icons-material/Edit";
import { ptBR } from "@mui/x-data-grid/locales";
import * as React from "react";
import { useState, useEffect } from "react";
import { userRequest } from "../../requestMethods";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import { useCompany } from "../../components/context/CompanyContext";
import Loading from "../../components/loading/loading";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { CategoryImage } from "../../components/CategoryImage/index";
import Avatar from "@mui/material/Avatar";
import { generateFileUrl } from "../../requestMethods";

export default function CategoryList() {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [isLoading, setIsLoading] = useState(false);
  const { companyId, isRetail } = useCompany();
  const [subCategories, setSubCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [inputs, setInputs] = useState({});
  const [file, setFile] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [nameCat, setNameCat] = useState();
  const [weight, setWeight] = useState();
  const [height, setHeight] = useState();
  const [width, setWidth] = useState();
  const [length, setLength] = useState();
  const [subCategory, setSubCategory] = useState();

  useEffect(() => {
    setIsLoading(true);
    if (companyId > 0) {
      getCategories();
    }
    setIsLoading(false);
  }, [companyId]);

  const getCategories = async () => {
    const res = await userRequest.get(`${companyId}/products/categories`);
    setCategories(res.data);
  };

  const handleChangeCat = (e) => {
    setCategory(parseInt(e));
    if (category !== parseInt(e) && parseInt(e) > 0) {
      setSubCategories(
        categories.find((item) => item.id === parseInt(e)).subCategories
      );
    }
  };
  const uploadImgCategory = async (categoryId) => {
    const formData = new FormData();
    formData.append("file", file);
    const res = await userRequest.post(
      `${companyId}/products/categories/${categoryId}`,
      formData
    );

    if (res.status === 200) {
      return {
        success: true,
        imgUrl: res.data.imageUrls.newName,
      };
    } else {
      return {
        success: false,
      };
    }
  };
  const addCategory = async () => {
    let valida = false;
    if (
      weight !== "" &&
      weight !== undefined &&
      height !== "" &&
      height !== undefined &&
      width !== "" &&
      width !== undefined &&
      length !== "" &&
      length !== undefined
    ) {
      valida = true;
    }
    if (!valida) {
      if (
        (weight === "" || weight === undefined) &&
        (height === "" || height === undefined) &&
        (width === "" || width === undefined) &&
        (length === "" || length === undefined)
      ) {
        valida = true;
      } else {
        Swal.fire(
          "Atenção!",
          "Os campos Peso, Altura, Largura, Comprimento devem ser preenchidos.",
          "warning"
        );
      }
    }
    if (valida) {
      if (nameCat !== "" && nameCat !== undefined) {
        let cat = categories.find(
          (item) => item.label === nameCat.toString().toUpperCase()
        );
        if (!cat) {
          const res = await userRequest.post(
            `${companyId}/products/categories/`,
            {
              label: nameCat,
              weight: weight,
              height: height,
              width: width,
              length: length,
              lastUserUpdate: currentUser.userId,
            }
          );
          if (file && res.data && res.data.categoryId) {
            const successUpload = await uploadImgCategory(res.data.categoryId);

            returnMsgImg(successUpload);
          } else {
            if (Object.keys(res.data).length > 0) {
              Swal.fire(
                "Parabéns!",
                "Categoria criada com sucesso!",
                "success"
              );
              getCategories();
            }
          }
        } else {
          Swal.fire("Atenção!", "Categoria existente!", "warning");
        }
      } else {
        Swal.fire(
          "Atenção!",
          "O campo Categoria deve ser preenchido.",
          "warning"
        );
      }
    }
  };
  function returnMsgImg(successUpload) {
    if (successUpload.success) {
      if (isEdit) {
        Swal.fire("Parabéns!", "Imagem alterada com sucesso!", "success");
      } else {
        Swal.fire("Parabéns!", "Categoria criada com sucesso!", "success");
      }

      getCategories();
    } else {
      Swal.fire("Atenção!", "Erro ao adicionar imagem à categoria", "error");
    }
  }
  const addSubCategory = async () => {
    if (subCategory !== "" && subCategory !== undefined) {
      let cat = subCategories.find(
        (item) => item.ref === subCategory.toString().toUpperCase()
      );

      if (!cat) {
        const res = await userRequest.post(
          `${companyId}/products/categories/`,
          {
            label: subCategory,
            parentCategoryId: category,
            lastUserUpdate: currentUser.userId,
          }
        );

        if (Object.keys(res.data).length > 0) {
          Swal.fire(
            "Parabéns!",
            "Sub Categoria criada com sucesso!",
            "success"
          );
          getCategories();
          setSubCategories([]);
        }
      } else {
        Swal.fire("Atenção!", "Sub Categoria existente!", "warning");
      }
    } else {
      Swal.fire(
        "Atenção!",
        "O campo Sub Categoria deve ser preenchido.",
        "warning"
      );
    }
  };
  const handleChange = (e) => {
    if (e.target.name === "category") {
      setNameCat(e.target.value);
    }
    if (e.target.name === "weight") {
      setWeight(e.target.value);
    }
    if (e.target.name === "height") {
      setHeight(e.target.value);
    }
    if (e.target.name === "width") {
      setWidth(e.target.value);
    }
    if (e.target.name === "length") {
      setLength(e.target.value);
    }
    if (e.target.name === "subcategory") {
      setSubCategory(e.target.value);
    }
    // setInputs((prev) => {
    //   return { ...prev, [e.target.name]: e.target.value };
    // });
  };

  const onButtonOffClick = async (e, categoryId) => {
    if (currentUser.isAdmin) {
      const res = await userRequest.put(
        `${companyId}/products/categories/logicaldelete/${categoryId}`,
        { deleted: new Date(), lastUserUpdate: currentUser.userId }
      );

      if (Object.keys(res.data).length > 0) {
        getCategories();
        setSubCategories([]);
      } else {
        Swal.fire("Atenção!", "Erro ao desabilitar categoria!", "error");
      }
    } else {
      Swal.fire(
        "Atenção!",
        "Você não tem permissão para editar a categoria!",
        "warning"
      );
    }
  };
  const onButtonOnClick = async (e, categoryId) => {
    if (currentUser.isAdmin) {
      const res = await userRequest.put(
        `${companyId}/products/categories/logicaldelete/${categoryId}`,
        { deleted: null, lastUserUpdate: currentUser.userId }
      );

      if (Object.keys(res.data).length > 0) {
        getCategories();
        setSubCategories([]);
      } else {
        Swal.fire("Atenção!", "Erro ao desabilitar categoria!", "error");
      }
    } else {
      Swal.fire(
        "Atenção!",
        "Você não tem permissão para editar a categoria!",
        "warning"
      );
    }
  };
  const onButtonEditarClick = (e, row) => {
    setIsEdit(true);
    setNameCat(row.label);
    setWeight(row.weight);
    setHeight(row.height);
    setWidth(row.width);
    setLength(row.length);
    if (row.categoryImage) {
      setFile(row.categoryImage);
    } else {
      setFile(undefined);
    }
  };
  const columns = [
    {
      field: "deleted",
      headerName: "",
      width: 50,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      renderCell: (params) => {
        if (params.row.deleted === null || params.row.deleted === null) {
          return (
            <IconButton onClick={(e) => onButtonOffClick(e, params.row.id)}>
              <VisibilityOutlinedIcon />
            </IconButton>
          );
        } else {
          return (
            <IconButton onClick={(e) => onButtonOnClick(e, params.row.id)}>
              <VisibilityOffOutlinedIcon />
            </IconButton>
          );
        }
      },
    },
    {
      field: "action",
      headerName: "",
      width: 50,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      renderCell: (params) => {
        if (currentUser.isAdmin) {
          return (
            <>
              <IconButton onClick={(e) => onButtonEditarClick(e, params.row)}>
                <EditIcon />
              </IconButton>
            </>
          );
        }
      },
    },
    {
      field: "categoryImage",
      headerName: "Foto",
      headerClassName: "dataGridMuiHeader",
      align: "center",
      headerAlign: "center",
      width: 50,
      display: "flex",
      renderCell: (params) => {
        return (
          <>
            <Avatar src={generateFileUrl(params.value)} />
          </>
        );
      },
    },
    // {
    //   field: "id",
    //   headerName: "Id",
    //   width: 50,
    //   align: "center",
    //   headerAlign: "center",
    //   headerClassName: "dataGridMuiHeader",
    // },
    {
      field: "label",
      headerName: "Categoria",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName: "dataGridMuiHeader",
      editable: true,
    },
    {
      field: "weight",
      headerName: "Peso(g)",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      editable: true,
    },
    {
      field: "height",
      headerName: "Altura(cm)",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      editable: true,
    },
    {
      field: "width",
      headerName: "Largura(cm)",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      editable: true,
    },
    {
      field: "length",
      headerName: "Comprimento(cm)",
      width: 200,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      editable: true,
    },
  ];
  const columnsSub = [
    {
      field: "deleted",
      headerName: "",
      width: 80,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      renderCell: (params) => {
        if (params.row.deleted === null || params.row.deleted === null) {
          return (
            <IconButton onClick={(e) => onButtonOffClick(e, params.row.id)}>
              <VisibilityOutlinedIcon />
            </IconButton>
          );
        } else {
          return (
            <IconButton onClick={(e) => onButtonOnClick(e, params.row.id)}>
              <VisibilityOffOutlinedIcon />
            </IconButton>
          );
        }
      },
    },
    {
      field: "id",
      headerName: "Id",
      width: 50,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "label",
      headerName: "Categoria",
      width: 500,
      align: "left",
      headerAlign: "left",
      headerClassName: "dataGridMuiHeader",
      editable: true,
    },
  ];

  const useFakeMutation = () => {
    return React.useCallback(
      (obj) =>
        new Promise(async (resolve, reject) => {
          if (currentUser.isAdmin) {
            const res = await userRequest.put(
              `${companyId}/products/categories/${obj.id}`,
              {
                label: obj.label,
                weight: obj.weight,
                height: obj.height,
                width: obj.width,
                length: obj.length,
                lastUserUpdate: currentUser.userId,
              }
            );

            if (Object.keys(res.data).length > 0) {
              resolve(obj);
              Swal.fire(
                "Parabéns!",
                "Categoria alterada com sucesso!",
                "success"
              );
              getCategories();
              setSubCategories([]);
            } else {
              reject(new Error("Erro atualizar status"));
            }
          } else {
            Swal.fire(
              "Atenção!",
              "Você não tem permissão para editar a categoria!",
              "warning"
            );
          }
        }),
      []
    );
  };
  const mutateRow = useFakeMutation();
  const AlterarLabel = React.useCallback(async (obj) => {
    const res = await mutateRow(obj);
    return obj;
  }, []);
  const handleProcessRowUpdateError = React.useCallback((error) => {
    console.error("error", error);
  }, []);

  async function updateImage() {
    const successUpload = await uploadImgCategory(category);

    returnMsgImg(successUpload);
  }
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const GridToolbarExport = ({
    csvOptions,
    printOptions,
    excelOptions,
    ...other
  }) => (
    <GridToolbarExportContainer {...other}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridExcelExportMenuItem options={excelOptions} />
    </GridToolbarExportContainer>
  );
  return (
    <div className="div_custom_">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="div_custom_Container">
          <div className="div_custom_UpdateList">
            <div className="div_custom_UpdateItem">
              <label style={{ marginBottom: "16px" }}>Categorias</label>
            </div>
            <div>
              <table style={{ borderSpacing: "0px" }}>
                <tr style={{ padding: "0px" }}>
                  <td className="tdCustom">Nome Categoria</td>
                  <td>Peso</td>
                  <td>Altura</td>
                  <td>Largura</td>
                  <td>Comprimento</td>
                </tr>
                <tr style={{ padding: "0px" }}>
                  <td className="tdCustom">
                    <input
                      className="div_custom_UpdateInputShort"
                      style={{ margin: "0px", width: "150px" }}
                      type="text"
                      name="category"
                      onChange={handleChange}
                      defaultValue={nameCat}
                      disabled={isEdit}
                    />
                  </td>
                  <td className="tdCustom">
                    <input
                      className="div_custom_UpdateInputShort"
                      style={{ margin: "0px 5px 0px 0px", width: "100px" }}
                      type="number"
                      name="weight"
                      onChange={handleChange}
                      maxLength="100"
                      defaultValue={weight}
                      disabled={isEdit}
                    />
                    g
                  </td>
                  <td className="tdCustom">
                    <input
                      className="div_custom_UpdateInputShort"
                      style={{ margin: "0px 5px 0px 0px", width: "100px" }}
                      type="number"
                      name="height"
                      onChange={handleChange}
                      maxLength="100"
                      defaultValue={height}
                      disabled={isEdit}
                    />
                    cm
                  </td>
                  <td className="tdCustom">
                    <input
                      className="div_custom_UpdateInputShort"
                      style={{ margin: "0px 5px 0px 0px", width: "100px" }}
                      type="number"
                      name="width"
                      onChange={handleChange}
                      maxLength="100"
                      defaultValue={width}
                      disabled={isEdit}
                    />
                    cm
                  </td>
                  <td className="tdCustom">
                    <input
                      className="div_custom_UpdateInputShort"
                      style={{ margin: "0px 5px 0px 0px", width: "100px" }}
                      type="number"
                      name="length"
                      onChange={handleChange}
                      maxLength="100"
                      defaultValue={length}
                      disabled={isEdit}
                    />
                    cm
                  </td>
                </tr>
              </table>
              {/* <label className="custom_label">Nome Categoria:</label>
              <input
                type="text"
                name="category"
                onChange={handleChange}
                maxLength="100"
              /> */}
              <CategoryImage
                file={file}
                setFile={setFile}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                categoryId={category}
              />
              {isEdit && file && file.name ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  className="custom_mui_action"
                  startIcon={<SaveIcon />}
                  onClick={updateImage}
                >
                  Adicionar Imagem
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="secondary"
                  className="custom_mui_action"
                  startIcon={<SaveIcon />}
                  onClick={addCategory}
                >
                  Adicionar Categoria
                </Button>
              )}
            </div>
            <div className="div_custom_UpdateLeft">
              <div style={{ height: 500 }}>
                <DataGridPremium
                  sx={{
                    fontFamily: "Manrope",
                    fontWeight: "500",
                    border: "0px",
                  }}
                  rowHeight={45}
                  localeText={
                    ptBR.components.MuiDataGrid.defaultProps.localeText
                  }
                  slots={{ toolbar: CustomToolbar }}
                  slotProps={{
                    panel: {
                      sx: {
                        top: "15px !important",
                        left: "50px !important",
                        position: "fixed !important",
                      },
                    },
                  }}
                  onRowSelectionModelChange={handleChangeCat}
                  getRowId={(row) => row.id}
                  rows={categories}
                  disableSelectionOnClick
                  columns={columns}
                  pagination
                  autoPageSize
                  processRowUpdate={AlterarLabel}
                  onProcessRowUpdateError={handleProcessRowUpdateError}
                />
              </div>
            </div>
            <div className="div_custom_UpdateItem">
              <label>Sub Categorias</label>
            </div>
            <div>
              <label className="custom_label">Nome Sub Categoria:</label>
              <input
                type="text"
                name="subcategory"
                onChange={handleChange}
                maxLength="100"
              />
              <div>
                <Button
                  variant="outlined"
                  color="secondary"
                  className="custom_mui_action"
                  startIcon={<SaveIcon />}
                  onClick={addSubCategory}
                >
                  Adicionar Sub Categoria
                </Button>
              </div>
            </div>
            <div className="div_custom_UpdateLeft">
              <div style={{ height: 500 }}>
                <DataGridPremium
                  sx={{
                    fontFamily: "Manrope",
                    fontWeight: "500",
                    border: "0px",
                  }}
                  rowHeight={45}
                  localeText={
                    ptBR.components.MuiDataGrid.defaultProps.localeText
                  }
                  slots={{ toolbar: CustomToolbar }}
                  slotProps={{
                    panel: {
                      sx: {
                        top: "15px !important",
                        left: "50px !important",
                        position: "fixed !important",
                      },
                    },
                  }}
                  getRowId={(row) => row.id + row.label}
                  rows={subCategories}
                  disableSelectionOnClick
                  columns={columnsSub}
                  pagination
                  autoPageSize
                  processRowUpdate={AlterarLabel}
                  onProcessRowUpdateError={handleProcessRowUpdateError}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
