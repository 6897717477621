/* eslint-disable jsx-a11y/alt-text */
import "../../components/DragDropFiles.css";
import "./product.css";
import "../../global.css";
import { arrayMoveImmutable } from "array-move";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useCompany } from "../../components/context/CompanyContext";
import Loading from "../../components/loading/loading";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { userRequest } from "../../requestMethods";
import {
  formatPricing,
  formatPricingDatabase,
  returnFormData,
  returnValidaVideo,
} from "../../util/helper";
import { FileBox } from "./components/FileBox";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import VideoImg from "../../components/VideoImg";
import FormControl from "@mui/material/FormControl";
import { MultiSelect } from "react-multi-select-component";
export default function Product() {
  const location = useLocation();
  const currentUser = useSelector((state) => state.user.currentUser);
  const productId = location.pathname.split("/")[3];
  const [product, setProduct] = useState();
  const [sizes, setSizes] = useState([{}]);
  const [colors, setColors] = useState([{}]);
  const [inputs, setInputs] = useState({});
  const [categories, setCategories] = useState();
  const [subCategories, setSubCategories] = useState();
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [fullPrice, setFullPrice] = useState();
  const [fullPriceRetail, setFullPriceRetail] = useState();
  const [price, setPrice] = useState();
  const [priceRetail, setPriceRetail] = useState();
  const [discount, setDiscount] = useState();
  const [discountRetail, setDiscountRetail] = useState();
  const [files, setFiles] = useState([]);
  const inputRef = useRef();
  const [grid, setGrid] = useState([]);
  const [colorsUsed, setColorsUsed] = useState([]);
  const [sizesUsed, setSizesUsed] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [enabled, setEnabled] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageSnackbar, setMessageSnackbar] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const snackbarTime = 4000;
  const [premium, setPremium] = useState();
  const [products, setProducts] = useState();
  const [provador, setProvador] = useState();
  const [provadorIni, setProvadorIni] = useState();
  const [stories, setStories] = useState([]);
  const [store, setStore] = useState(0);
  const inputRefVideo = useRef();

  const { companyId, isRetail } = useCompany();

  const onSortEnd = (oldIndex, newIndex) => {
    setFiles((array) => arrayMoveImmutable(array, oldIndex, newIndex));
  };

  const nav = useNavigate();

  useEffect(() => {
    setIsLoading(true);

    if (companyId > 0) {
      fnInicial();
    }

    setIsLoading(false);
  }, [companyId]);

  useEffect(() => {
    if (categories) {
      if (productId !== "new") {
        getProduct();
      }
    }
  }, [categories]);
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
  const getStories = async () => {
    const res = await userRequest.get(`${companyId}/store/stories`);
    setStories(res.data);
  };
  function fnInicial() {
    getCategories();
    getSizes();
    getColors();
    getProducts();
    getStories();
  }
  async function fnSetProduct(prd) {
    setIsLoading(true);
    if (prd.enabled) {
      setEnabled(prd.enabled);
    }

    if (prd.presales) {
      setPremium(prd.presales);
    }
    if (prd.images !== null && prd.images !== undefined) {
      if (prd.images.length > 0) {
        setFiles(prd.images);
      }
    }
    if (prd.videos) {
      setProvador(prd.videos);
      setProvadorIni(true);
    } else {
      setProvadorIni(false);
    }
    if (prd.grid !== null && prd.grid !== undefined) {
      if (prd.grid.length > 0) {
        setGrid(prd.gridId);
      }
    }
    // WHOSALE
    if (prd.discount !== "" && prd.discount !== undefined) {
      setFullPrice(prd.discount);
    }
    if (prd.price !== "" && prd.price !== undefined) {
      setPrice(prd.price);
    }
    setDiscount(
      calcDiscount(prd.price ? prd.price : 0, prd.discount ? prd.discount : 0)
    );

    // RETAIL
    if (prd.fullPriceVarejo !== "" && prd.fullPriceVarejo !== undefined) {
      setFullPriceRetail(prd.fullPriceVarejo);
    }
    if (prd.priceVarejo !== "" && prd.priceVarejo !== undefined) {
      setPriceRetail(prd.priceVarejo);
    }
    setDiscountRetail(
      calcDiscount(
        prd.priceVarejo ? prd.priceVarejo : 0,
        prd.fullPriceVarejo ? prd.fullPriceVarejo : 0
      )
    );
    if (categories) {
      if (prd.category && prd.category.categoryId) {
        setCategory(prd.category.categoryId);
      }
      if (prd.subCategory && prd.subCategory.categoryId) {
        setSubCategories(
          categories.filter(
            (item) => item.parentCategoryId === prd.category.categoryId
          )
        );
        setSubCategory(prd.subCategory.categoryId);
      }
    }
    setIsLoading(false);
  }
  const handleOnDeleteVideo = (event) => {
    setProvadorIni(false);
    setProvador();

    const res = userRequest.delete(
      `${companyId}/products/images/deleteprovador/${productId}`
    );

    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  };
  const getProducts = async () => {
    try {
      const res = await userRequest.get(`${companyId}/products/admin`);
      setProducts(res.data);
    } catch (err) {}
  };
  const getProduct = async () => {
    const res = await userRequest.get(
      `${companyId}/products/${productId}&${store}`
    );
    fnSetProduct(res.data);
    setProduct(res.data);
  };

  const getSizes = async () => {
    const res = await userRequest.get(`${companyId}/products/sizes`);
    setSizes(res.data);
  };

  const getColors = async () => {
    const res = await userRequest.get(`${companyId}/products/colors`);
    setColors(res.data);
  };

  const getCategories = async () => {
    const res = await userRequest.get(`${companyId}/products/categories/admin`);
    setCategories(res.data);
  };

  const calcDiscount = (sPrice, sFullPrice) => {
    if (sFullPrice > 0) {
      return parseFloat(((1 - sPrice / sFullPrice) * 100).toFixed(1));
    }
  };

  const calcPrice = (sFullPrice, sDiscount) => {
    if (sDiscount > 0) {
      return parseFloat(
        sFullPrice - (sFullPrice * (sDiscount / 100)).toFixed(2)
      );
    }
    return sFullPrice;
  };

  const validate = () => {
    if (inputs.title === "" || inputs.title === undefined) {
      Swal.fire("Atenção!", "O campo Produto deve ser preenchido.", "warning");
      return false;
    }
    if (inputs.ref === "" || inputs.ref === undefined) {
      Swal.fire(
        "Atenção!",
        "O campo Referência deve ser preenchido.",
        "warning"
      );
      return false;
    }
    let ref = products.find(
      (item) =>
        item.ref.toUpperCase().trim() ===
        inputs.ref.toString().toUpperCase().trim()
    );
    if (ref) {
      Swal.fire("Atenção!", "Essa Referência já está cadastrada.", "warning");
      return false;
    }
    if (!isRetail && (price === "" || price === undefined || price <= 0)) {
      Swal.fire("Atenção!", "O campo Preço deve ser preenchido.", "warning");
      return false;
    }
    if (
      isRetail &&
      isRetail === "both" &&
      (price === "" ||
        price === undefined ||
        price <= 0 ||
        priceRetail === "" ||
        priceRetail === undefined ||
        priceRetail <= 0)
    ) {
      Swal.fire(
        "Atenção!",
        "Os campos de Preço devem ser preenchido.",
        "warning"
      );
      return false;
    }
    if (
      isRetail &&
      isRetail === "true" &&
      (priceRetail === "" || priceRetail === undefined || priceRetail <= 0)
    ) {
      Swal.fire("Atenção!", "O campo Preço deve ser preenchido.", "warning");
      return false;
    }
    if (!subCategory && !category) {
      Swal.fire(
        "Atenção!",
        "O campo Categoria deve ser preenchido.",
        "warning"
      );
      return false;
    }
    if (grid.length === 0 || grid === undefined) {
      Swal.fire("Atenção!", "Você deve selecionar cor e tamanho.", "warning");
      return false;
    }

    return true;
  };

  const updateClick = async (event) => {
    setIsLoading(true);

    try {
      if (product) {
        await AlterarProduto();
      } else {
        if (validate()) {
          await InserirProdut();
        }
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const AlterarProduto = async () => {
    try {
      await handleUploadVideo(productId);
      const filesToUpload = files.filter((file) => typeof file !== "string");

      let images = [];
      if (!filesToUpload.length) {
        images = files;
      } else {
        const imageUrls = await handleUpload(productId);

        const payloadFiles = files.map((img) => {
          if (typeof img === "string") {
            return img;
          }
          const find = imageUrls.find((imgFind) => {
            const extOn = img.name.slice(-3).toUpperCase();

            let newOriginalName = img.name;
            if (extOn === "JPG" || extOn === "PNG") {
              newOriginalName = `${img.name.slice(0, -4)}.JPEG`;
            }

            return (
              imgFind.originalName.trim().toUpperCase() ===
              newOriginalName.trim().toUpperCase()
            );
          });

          return find.newName || "error-find";
        });

        images = payloadFiles;
      }
      await userRequest.put(`${companyId}/products/${productId}`, {
        title: inputs.title,
        ref: inputs.ref,
        shortDesc: inputs.shortDesc,
        desc: inputs.desc,
        price: price,
        discount: fullPrice,
        priceVarejo: priceRetail,
        fullPriceVarejo: fullPriceRetail,
        grid: grid,
        enabled: enabled,
        images: images,
        lastUserUpdate: currentUser.userId,
        presales: premium,
        visibleAt: enabled ? new Date() : null,
        categoryId:
          subCategory > 0 ? subCategory : category > 0 ? category : null,
      });

      Swal.fire("Parabéns!", "Produto alterado com sucesso!", "success");
      nav(`../products`);
    } catch (error) {
      console.error(error);
      Swal.fire("Erro!", "Erro ao gravar o produto!", "warning");
    }
  };

  const InserirProdut = async () => {
    try {
      const today = new Date();

      const payloadInsert = [
        {
          title: inputs.title,
          ref: inputs.ref,
          shortDesc: inputs.shortDesc,
          desc: inputs.desc,
          price: price,
          discount: fullPrice,
          grid: grid,
          images: [],
          enabled: enabled,
          priceVarejo: priceRetail,
          fullPriceVarejo: fullPriceRetail,
          lastUserUpdate: currentUser.userId,
          visibleAt: enabled ? today : null,
          presales: premium,
          categoryId:
            subCategory > 0 ? subCategory : category > 0 ? category : null,
        },
      ];

      const res = await userRequest.post(
        `${companyId}/products/`,
        payloadInsert
      );

      let imageUrls = await handleUpload(res.data[0].productId);
      await handleUploadVideo(res.data[0].productId);
      imageUrls = imageUrls.map((imageUrl) => imageUrl.newName);

      await userRequest.put(`${companyId}/products/${res.data[0].productId}`, {
        images: imageUrls,
      });

      Swal.fire("Parabéns!", "Produto adicionado com sucesso!", "success");
      nav(`../products`);
    } catch (error) {
      console.error(error);
      Swal.fire("Erro!", "Erro ao gravar o produto!", "error");
    }
  };

  const handleChangeGrid = (stock, color, size, store) => {
    if (stock !== _findStock(color, size)) {
      setGrid((prev) => [
        ...prev.filter(
          (item) =>
            !(
              item.colorId === color &&
              item.sizeId === size &&
              item.storeId === store
            )
        ),
        {
          colorId: color,
          sizeId: size,
          stock: stock ? parseInt(stock) : null,
          storeId: store,
        },
      ]);
    }
  };
  useEffect(() => {
    setSizesUsed([
      ...new Set(
        grid.filter((s) => s.storeId === store).map((objeto) => objeto.sizeId)
      ),
    ]);
    setColorsUsed([
      ...new Set(
        grid.filter((s) => s.storeId === store).map((objeto) => objeto.colorId)
      ),
    ]);
  }, [grid, store]);

  const handleChangeSizes = (e) => {
    if (e.target.checked) {
      if (colorsUsed.length > 0) {
        colorsUsed.map((colors) => {
          setGrid((prev) => [
            ...prev,
            {
              colorId: parseInt(colors),
              sizeId: parseInt(e.target.value),
              stock: inputs.qtdeColor ? parseInt(inputs.qtdeColor) : 0,
              storeId: store,
            },
          ]);
        });
      } else {
        setSizesUsed((prev) => [...prev, parseInt(e.target.value)]);
      }
    } else {
      if (colorsUsed.length === 0) {
        setSizesUsed((prev) => [
          ...prev.filter((item) => item.colorId === e.target.value),
        ]);
      } else {
        Swal.fire(
          "Atenção!",
          "Você não pode excluir pois existe uma cor atrelado!",
          "warning"
        );
      }
    }
  };
  const handleChangeColors = (e) => {
    if (e.target.checked) {
      if (sizesUsed.length > 0) {
        sizesUsed.map((sizes) => {
          setGrid((prev) => [
            ...prev,
            {
              colorId: parseInt(e.target.value),
              sizeId: sizes,
              stock: inputs.qtdeColor ? parseInt(inputs.qtdeColor) : 0,
              storeId: store,
            },
          ]);
        });
      } else {
        Swal.fire(
          "Atenção!",
          "Você deve selecionar os tamanhos desejados!",
          "warning"
        );
      }
    } else {
      sizesUsed.map((sizes) => {
        setGrid((prev) => [
          ...prev.filter(
            (item) =>
              !(
                item.colorId === parseInt(e.target.value) &&
                item.sizeId === sizes &&
                item.storeId === store
              )
          ),
        ]);
      });
    }
  };
  const handleChangeEnabled = (e) => {
    setEnabled(e.target.checked);
  };

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleChangeFullPrice = (e) => {
    setFullPrice(formatPricingDatabase(e.target.value));
    setPrice(calcPrice(formatPricingDatabase(e.target.value), discount));
  };
  const handleChangeFullPriceRetail = (e) => {
    setFullPriceRetail(formatPricingDatabase(e.target.value));
    setPriceRetail(
      calcPrice(formatPricingDatabase(e.target.value), discountRetail)
    );
  };
  const handleChangeDiscount = (e) => {
    setDiscount(formatPricingDatabase(e.target.value));
    setPrice(calcPrice(fullPrice, formatPricingDatabase(e.target.value)));
  };
  const handleChangeDiscountRetail = (e) => {
    setDiscountRetail(formatPricingDatabase(e.target.value));
    setPriceRetail(
      calcPrice(fullPriceRetail, formatPricingDatabase(e.target.value))
    );
  };
  const handleChangeCat = (e) => {
    setSubCategory(null);
    setCategory(parseInt(e.target.value));

    if (category !== parseInt(e.target.value) && parseInt(e.target.value) > 0) {
      setSubCategories(
        categories.filter(
          (item) => item.parentCategoryId === parseInt(e.target.value)
        )
      );
    }
  };
  const handleChangeStore = (e) => {
    setStore(parseInt(e.target.value));
  };
  const handleChangeSubCat = (e) => {
    setSubCategory(parseInt(e.target.value));
  };

  const handleUpload = async (productId) => {
    try {
      const filesNew = files.filter((f) => typeof f !== "string");

      if (!filesNew.length) {
        return [];
      }

      const formData = await returnFormData(filesNew, "foto");
      const res = await userRequest.post(
        `${companyId}/products/images/${productId}`,
        formData
      );

      return res.data.imageUrls;
    } catch (error) {
      return error;
    }
  };
  const handleUploadVideo = async (productId) => {
    if (!provadorIni && provador) {
      const formData = await returnFormData([provador], "foto", "provador");

      const res = await userRequest.post(
        `${companyId}/products/images/${productId}`,
        formData
      );

      if (res.status !== 200) {
        return false;
      }
    }
    return true;
  };

  const handleOnChange = (event) => {
    const evFiles = event.target.files;

    if (returnValidaVideo(evFiles, false)) {
      const eventFiles = Object.keys(evFiles).map((key) => evFiles[key]);
      const filesNew = [...files, ...eventFiles];

      setFiles(filesNew);
    }
  };

  const handleOnclick = (event) => {
    event.preventDefault();
    inputRef.current.click();
  };

  function removeFileBeforeCreateProduct(file) {
    const newFiles = files.filter((f) => f.name !== file.name);
    setFiles(newFiles);
  }

  const handleOneDeleteFile = async (item) => {
    try {
      await userRequest.put(`${companyId}/products/images/${productId}`, {
        image: item,
      });

      const newFiles = files.filter((file) => file !== item);
      setFiles(newFiles);
      snackbarAction("Imagem deletada com sucesso", "success");
    } catch (error) {
      console.error(error);
      snackbarAction("Erro ao deletar imagem", "error");
    }
  };

  const _findStock = (color, size) => {
    const obj = grid.find(
      (item) =>
        item.colorId === color && item.sizeId === size && item.storeId === store
    );

    return obj ? obj.stock : null;
  };

  function snackbarAction(message, severity) {
    setMessageSnackbar(message);
    setOpenSnackbar(true);
    setAlertSeverity(severity);

    setTimeout(() => {
      setMessageSnackbar("");
      setOpenSnackbar(false);
      setAlertSeverity("");
    }, snackbarTime);
  }
  const handleOnChangeVideo = (event) => {
    if (returnValidaVideo(event.target.files, true)) {
      Array.from(event.target.files).map((item, index) => setProvador(item));
      setProvadorIni(false);
    }
  };
  // const handleChangePremium = (event) => {
  //   setPremium(event.target.checked);
  // };
  const handleOnclickVideo = (event) => {
    event.preventDefault();
    inputRefVideo.current.click();
  };
  function createdAndEditedVideo(file) {
    return (
      (!product && file.type && file.type === "video/mp4") ||
      (product && file.type && file.type === "video/mp4")
    );
  }

  function createdAndEditedImage(file) {
    return (
      (!product && file.type && file.type !== "video/mp4") ||
      (product && file.type && file.type !== "video/mp4")
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="div_custom_Form">
          {product ? (
            <span className="div_custom_UpdateTitle">Editar Produto</span>
          ) : (
            <span className="div_custom_UpdateTitle">Novo Produto</span>
          )}
          <div className="div_custom_UpdateLeft">
            <div className="div_custom_UpdateItem">
              <label>Pré-venda</label>
              <input
                className="star"
                type="checkbox"
                name="presales"
                checked={premium}
                //onChange={handleChangePremium}
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Produto</label>
              <input
                type="text"
                className="div_custom_UpdateInput"
                name="title"
                defaultValue={product ? product.title : ""}
                onChange={handleChange}
                maxLength="50"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Referência</label>
              <input
                type="text"
                name="ref"
                defaultValue={product ? product.ref : ""}
                className="div_custom_UpdateInput"
                onChange={handleChange}
                maxLength="15"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Breve Descrição</label>
              <input
                type="text"
                name="shortDesc"
                className="div_custom_UpdateInput"
                defaultValue={product ? product.shortDesc : ""}
                onChange={handleChange}
                maxLength="100"
                onKeyDown={(e) => {
                  if (e.keyCode === 8 || e.keyCode === 46) {
                    setInputs((prev) => {
                      return { ...prev, [e.target.name]: null };
                    });
                  }
                }}
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Descrição</label>
              <textarea
                rows="5"
                type="text"
                name="desc"
                className="div_custom_UpdateInput_textArea"
                defaultValue={product ? product.desc : ""}
                onChange={handleChange}
                maxLength="200"
                onKeyDown={(e) => {
                  if (e.keyCode === 8 || e.keyCode === 46) {
                    setInputs((prev) => {
                      return { ...prev, [e.target.name]: null };
                    });
                  }
                }}
              />
            </div>

            {!isRetail && (
              <>
                <div className="div_custom_UpdateItem">
                  <label>Preço Cheio</label>

                  <input
                    type="text"
                    name="discount"
                    className="div_custom_UpdateInput"
                    defaultValue={fullPrice ? formatPricing(fullPrice) : ""}
                    onChange={handleChangeFullPrice}
                  />
                </div>
                <div className="div_custom_UpdateItem">
                  <label>Desconto(%)</label>
                  <input
                    type="text"
                    name="discount"
                    className="div_custom_UpdateInput"
                    defaultValue={
                      discount ? discount.toString().replace(".", ",") : ""
                    }
                    onChange={handleChangeDiscount}
                  />
                </div>
                <div className="div_custom_UpdateItem">
                  <label>Preço (catálogo)</label>
                  <input
                    type="text"
                    name="price"
                    className="div_custom_UpdateInput"
                    defaultValue={price ? formatPricing(price) : ""}
                    disabled
                  />
                </div>
              </>
            )}

            {isRetail && isRetail === "both" && (
              <>
                <div className="div_custom_UpdateItem">
                  <label>Preço Cheio</label>

                  <input
                    type="text"
                    name="discount"
                    className="div_custom_UpdateInput"
                    defaultValue={fullPrice ? formatPricing(fullPrice) : ""}
                    onChange={handleChangeFullPrice}
                  />
                </div>
                <div className="div_custom_UpdateItem">
                  <label>Desconto(%)</label>
                  <input
                    type="text"
                    name="discount"
                    className="div_custom_UpdateInput"
                    defaultValue={
                      discount ? discount.toString().replace(".", ",") : ""
                    }
                    onChange={handleChangeDiscount}
                  />
                </div>
                <div className="div_custom_UpdateItem">
                  <label>Preço (catálogo)</label>
                  <input
                    type="text"
                    name="price"
                    className="div_custom_UpdateInput"
                    defaultValue={price ? formatPricing(price) : ""}
                    disabled
                  />
                </div>
              </>
            )}

            {isRetail && (isRetail === "true" || isRetail === "both") && (
              <>
                <div className="div_custom_UpdateItem">
                  <label>Preço Cheio Varejo</label>

                  <input
                    type="text"
                    name="full-price-retail"
                    className="div_custom_UpdateInput"
                    defaultValue={
                      fullPriceRetail ? formatPricing(fullPriceRetail) : ""
                    }
                    onChange={handleChangeFullPriceRetail}
                  />
                </div>
                <div className="div_custom_UpdateItem">
                  <label>Desconto(%) Varejo</label>
                  <input
                    type="text"
                    name="discount-retail"
                    className="div_custom_UpdateInput"
                    defaultValue={
                      discountRetail
                        ? discountRetail.toString().replace(".", ",")
                        : ""
                    }
                    onChange={handleChangeDiscountRetail}
                  />
                </div>
                <div className="div_custom_UpdateItem">
                  <label>Preço Varejo(catálogo)</label>
                  <input
                    type="text"
                    name="price-retail"
                    className="div_custom_UpdateInput"
                    defaultValue={priceRetail ? formatPricing(priceRetail) : ""}
                    disabled
                  />
                </div>
              </>
            )}

            <div>
              <label>Configuração Tamanhos e Cores</label>
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  fontSize: "12px",
                }}
              >
                <label
                  style={{
                    fontSize: "12px",
                  }}
                >
                  Loja
                </label>
                <select
                  className="div_custom_UpdateSelectShort"
                  name="store"
                  id="store"
                  onChange={handleChangeStore}
                  value={store ? store : 0}
                >
                  <option value="0">ONLINE</option>
                  {stories
                    ? stories.map((item) => (
                        <option value={item.storeId}>{item.store}</option>
                      ))
                    : ""}
                </select>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  fontSize: "12px",
                }}
              >
                <label
                  style={{
                    fontSize: "12px",
                  }}
                >
                  Quantidade Estoque
                </label>
                <input
                  className="div_custom_UpdateInputShort"
                  type="number"
                  name="qtdeColor"
                  style={{ margin: "0px 16px 0px 16px", fontSize: "12px" }}
                  onChange={handleChange}
                ></input>
              </div>
              <Box sx={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    flexFlow: "column wrap",
                    height: 320,
                    width: "30%",
                    overflow: "auto",
                    border: "1px solid #d7d8da",
                    borderRadius: "10px",
                    margin: "5px",
                    gap: "0 30px",
                  }}
                >
                  {sizes.map((size) => {
                    return (
                      <ListItem
                        key={size.ref}
                        sx={{
                          paddingTop: "0px",
                          paddingRight: "0px",
                          paddingBottom: "0px",
                          fontSize: "12px",
                          width: "auto",
                        }}
                      >
                        <label style={{ width: "30px" }}>{size.label}</label>
                        <input
                          className="largerCheckbox"
                          type="checkbox"
                          value={size.id}
                          onChange={handleChangeSizes}
                          checked={
                            sizesUsed && sizesUsed.length > 0
                              ? sizesUsed.findIndex((e) => e === size.id) > -1
                              : false
                          }
                        />
                      </ListItem>
                    );
                  })}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexFlow: "column wrap",
                    height: 320,
                    width: "70%",
                    overflow: "auto",
                    border: "1px solid #d7d8da",
                    borderRadius: "10px",
                    margin: "5px",
                    gap: "0 30px",
                  }}
                >
                  {colors.map((color) => {
                    return (
                      <ListItem
                        key={color.ref}
                        sx={{
                          paddingTop: "0px",
                          paddingRight: "0px",
                          paddingBottom: "0px",
                          fontSize: "12px",
                          width: "auto",
                        }}
                      >
                        <div
                          style={{
                            width: "30px",
                            height: "30px",
                            backgroundColor: color.rgb,
                            marginRight: "5px",
                          }}
                        ></div>
                        <label style={{ width: "80px" }}>{color.label}</label>

                        <input
                          className="largerCheckbox"
                          type="checkbox"
                          value={color.colorId}
                          onChange={handleChangeColors}
                          checked={
                            colorsUsed && colorsUsed.length > 0
                              ? colorsUsed.findIndex(
                                  (e) => e === color.colorId
                                ) > -1
                              : false
                          }
                        />
                      </ListItem>
                    );
                  })}
                </div>
              </Box>
              <table className="custom-minigrid-prd">
                <thead>
                  <tr>
                    <td>&nbsp;</td>
                    {sizes
                      .filter((s) =>
                        sizesUsed && sizesUsed.length > 0
                          ? sizesUsed.findIndex((e) => e === s.id) > -1
                          : false
                      )
                      .map((size) => (
                        <td key={size.ref}>{size.label}</td>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {colors
                    .filter((c) =>
                      colorsUsed && colorsUsed.length > 0
                        ? colorsUsed.findIndex((e) => e === c.colorId) > -1
                        : false
                    )
                    .map((color, iColor) => (
                      <tr key={color.ref}>
                        <td bgcolor={color.rgb}></td>
                        {sizes
                          .filter((s) =>
                            sizesUsed && sizesUsed.length > 0
                              ? sizesUsed.findIndex((e) => e === s.id) > -1
                              : false
                          )
                          .map((size, iSize) => (
                            <td>
                              <input
                                type="number"
                                className="div_custom_Input"
                                name="grid"
                                value={
                                  grid ? _findStock(color.id, size.id) : null
                                }
                                onChange={(e) =>
                                  handleChangeGrid(
                                    e.target.value,
                                    color.id,
                                    size.id,
                                    store
                                  )
                                }
                                onKeyDown={(e) => {
                                  if (e.keyCode === 8 || e.keyCode === 46) {
                                    handleChangeGrid(
                                      null,
                                      color.id,
                                      size.id,
                                      store
                                    );
                                  }
                                }}
                              />
                            </td>
                          ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="div_custom_UpdateItem">
              <label>Categoria</label>
              <select
                className="div_custom_Select"
                name="category"
                id="position"
                onChange={handleChangeCat}
                value={category && categories ? category : 0}
              >
                <option value="0"></option>
                {categories
                  ? categories
                      .filter((p) => p.parentCategoryId === null)
                      .map((item) => (
                        <option value={item.categoryId}>{item.label}</option>
                      ))
                  : false}
              </select>
            </div>
            <div className="div_custom_UpdateItem">
              <label>Sub Categoria</label>
              <select
                className="div_custom_Select"
                name="subCategory"
                id="position"
                onChange={handleChangeSubCat}
                value={category && subCategories ? subCategory : 0}
              >
                <option value="0"></option>
                {subCategories
                  ? subCategories.map((item) => (
                      <option value={item.categoryId}>{item.label}</option>
                    ))
                  : ""}
              </select>
            </div>
            <div className="div_custom_UpdateItem">
              <label>Produto Visível</label>
              <input
                className="largerCheckbox"
                type="checkbox"
                name="enabled"
                checked={enabled}
                onChange={handleChangeEnabled}
              />
            </div>
          </div>
          <div className="div_custom_UpdateItem">
            <label>Fotos</label>
          </div>
          <div>
            <div>
              <SortableList
                onSortEnd={onSortEnd}
                className="sortable-container"
              >
                {Object.keys(files).map((chave) => {
                  const file = files[chave];

                  return (
                    <SortableItem key={file} distance={1} pressDelay>
                      <div>
                        {createdAndEditedVideo(file) && (
                          <FileBox
                            file={file}
                            removeFileBeforeCreateProduct={
                              removeFileBeforeCreateProduct
                            }
                            handleOneDeleteFile={handleOneDeleteFile}
                            isNewFile
                          />
                        )}

                        {createdAndEditedImage(file) && (
                          <FileBox
                            file={file}
                            removeFileBeforeCreateProduct={
                              removeFileBeforeCreateProduct
                            }
                            handleOneDeleteFile={handleOneDeleteFile}
                            isImage
                            isNewFile
                          />
                        )}

                        {product &&
                          typeof file === "string" &&
                          file.slice(-3).toUpperCase() === "MP4" && (
                            <FileBox
                              removeFileBeforeCreateProduct={
                                removeFileBeforeCreateProduct
                              }
                              handleOneDeleteFile={handleOneDeleteFile}
                              file={file}
                            />
                          )}

                        {product &&
                          typeof file === "string" &&
                          file.slice(-3).toUpperCase() !== "MP4" && (
                            <FileBox
                              removeFileBeforeCreateProduct={
                                removeFileBeforeCreateProduct
                              }
                              handleOneDeleteFile={handleOneDeleteFile}
                              file={file}
                              isImage
                            />
                          )}
                      </div>
                    </SortableItem>
                  );
                })}
              </SortableList>
            </div>
            <div>
              <input
                type="file"
                multiple
                onChange={handleOnChange}
                hidden
                accept="image/png, image/jpeg, video/mp4"
                ref={inputRef}
              />
              <Button
                variant="outlined"
                className="custom_mui_action"
                onClick={handleOnclick}
              >
                Selecione as fotos/vídeos
              </Button>
            </div>
          </div>
          <div className="div_custom_UpdateItem">
            <label>Vídeo Provador</label>
          </div>
          <div>
            {provador ? (
              <div className="uploads">
                <VideoImg
                  filesIni={provadorIni}
                  item={provador}
                  classImg="bannerUpload"
                  classVideo="bannerUpload"
                />

                <div>
                  <Button
                    variant="outlined"
                    className="custom_mui_action"
                    onClick={handleOnDeleteVideo}
                  >
                    Excluir Vídeo
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <input
                  type="file"
                  onChange={handleOnChangeVideo}
                  hidden
                  accept="video/mp4"
                  ref={inputRefVideo}
                />
                {currentUser.isAdmin ? (
                  <Button
                    variant="outlined"
                    className="custom_mui_action"
                    onClick={handleOnclickVideo}
                  >
                    Selecione o vídeo
                  </Button>
                ) : (
                  false
                )}
              </div>
            )}
          </div>
          {product ? (
            <Button
              variant="outlined"
              color="secondary"
              className="custom_add_back"
              startIcon={<SaveIcon />}
              onClick={updateClick}
            >
              Alterar
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="secondary"
              className="custom_add_back"
              startIcon={<SaveIcon />}
              onClick={updateClick}
            >
              Criar
            </Button>
          )}
          <Button variant="outlined" color="secondary" onClick={() => nav(-1)}>
            Voltar
          </Button>
        </div>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={snackbarTime}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        TransitionComponent="Fade"
        action=""
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {messageSnackbar}
        </Alert>
      </Snackbar>
    </Container>
  );
}
