import { LicenseInfo } from "@mui/x-license";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { CompanyContextProvider } from "./components/context/CompanyContext";
import "./index.css";
import { persistor, store } from "./redux/store";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    secondary: {
      main: "#ee8253",
    },
  },
});

LicenseInfo.setLicenseKey(
  "1a1319b9888f8a1ed89f87654e82c9c2Tz0xMTA4MDksRT0xNzc1MjYwNzk5MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y"
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={"null"} persistor={persistor}>
        <CompanyContextProvider>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </CompanyContextProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
