import React, { createContext, useContext, useEffect, useState } from "react";
import {
  nameMooda,
  publicRequest,
  slugMooda,
  urlSiteMooda,
} from "../../requestMethods";

const initialCompanyContext = {
  minProduct: 15,
  imgLogo: "defaultLogo.svg",
  companyName: nameMooda,
  companyId: 0,
  slug: slugMooda,
  showDashboard: true,
  showCashback: false,
  enabledPayment: false,
  cashback: false,
  isRetail: undefined,
  layout: "default",
};

export const CompanyContext = createContext();

export const CompanyContextProvider = (props) => {
  const [data, setData] = useState({
    configRules: {
      minProducts: 0,
      cashback: true,
    },
    configUI: {
      imgLogo: [],
    },
    name: nameMooda,
    companyId: 0,
    slug: slugMooda,
    planContract: {
      showPrice: false,
      showDashboard: true,
      showCashback: false,
    },
    enabledPayment: false,
  });
  const [contextData, setContextData] = useState(initialCompanyContext);

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const [_ignoreFirstSlash, rootParam] = window.location.pathname.split("/");

    getCompany(rootParam.toLowerCase());
  }, []);

  const [logo, setLogo] = useState();
  const [minProducts, setMinProducts] = useState();
  const [cashback, setCashback] = useState();
  const [name, setName] = useState();
  const [showDashboard, setShowDashboard] = useState();
  const [showCashback, setShowCashback] = useState();
  const [isRetail, setIsRetail] = useState();
  const [layout, setLayout] = useState();

  const {
    configRules,
    configUI,
    name: remoteName,
    slug,
    companyId,
    planContract,
    enabledPayment,
  } = data;

  useEffect(() => {
    generateManifest(name ?? initialCompanyContext.companyName);
    setContextData({
      minProduct: minProducts ?? initialCompanyContext.minProduct,
      imgLogo: logo ?? initialCompanyContext.imgLogo,
      companyName: name ?? initialCompanyContext.companyName,
      companyId: companyId ?? initialCompanyContext.companyId,
      slug: slug ?? initialCompanyContext.slug,
      enabledPayment: enabledPayment ?? initialCompanyContext.enabledPayment,
      cashback: cashback ?? initialCompanyContext.cashback,
      showDashboard:
        planContract && planContract.showDashboard
          ? planContract.showDashboard
          : initialCompanyContext.showDashboard,
      showCashback:
        planContract && planContract.showCashback
          ? planContract.showCashback
          : initialCompanyContext.showCashback,
      isRetail: isRetail ?? initialCompanyContext.isRetail,
      layout: layout ?? initialCompanyContext.layout,
    });
  }, [
    logo,
    minProducts,
    name,
    companyId,
    slug,
    showDashboard,
    showCashback,
    enabledPayment,
    cashback,
    isRetail,
    layout,
  ]);

  useEffect(() => {
    if (configUI && configUI.imgLogo) {
      configUI.imgLogo.map((item) =>
        setLogo(item.newName ? item.newName : item)
      );
    }
    if (configRules && configRules.minProducts) {
      setMinProducts(configRules.minProducts);
    }
    if (configRules) {
      setCashback(configRules.cashback);
    }
    if (planContract && planContract.showDashboard) {
      setShowDashboard(planContract.showDashboard);
    }
    if (planContract && planContract.showCashback) {
      setShowCashback(planContract.showCashback);
    }
    if (configUI && configUI.isRetail) {
      setIsRetail(configUI.isRetail);
    }
    if (configUI && configUI.layout) {
      setLayout(configUI.layout);
    }
    setName(remoteName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getCompany = async (currentCompany) => {
    try {
      const res = await publicRequest.get(`/company/${currentCompany}`);

      if (Object.keys(res.data).length > 0) {
        //if (!res.data.planContract.moodapay) {
        setData(res.data);
        //}
      }
    } catch (error) {}
  };

  const generateManifest = (cp) => {
    var myDynamicManifest = {
      name: "Admin " + cp,
      short_name: "Admin " + cp,
      dir: "auto",
      display: "standalone",
      orientation: "Portrait",
      prefer_related_applications: false,
      related_applications: [],
      icons: [
        {
          src: "theme/icons/mooda_logo_icon.svg",
          sizes: "48x48 72x72 96x96 128x128 150x150 256x256 512x512",
          type: "image/svg+xml",
        },
      ],
      start_url: window.location.href,
      //background_color: "#000000",
      theme_color: "#EE8253",
    };

    if (cp !== "Mooda") {
      const stringManifest = JSON.stringify(myDynamicManifest);
      const blob = new Blob([stringManifest], { type: "application/json" });
      const manifestURL = URL.createObjectURL(blob);
      const manifest = document.getElementById("manisfestJson");
      if (manifest) {
        manifest.setAttribute("href", manifestURL);
      }
    }
  };
  return (
    <CompanyContext.Provider value={contextData}>
      {props.children}
    </CompanyContext.Provider>
  );
};

export const WithCompanyContext = (Component) => {
  const MixinComponent = (props) => {
    return (
      <CompanyContextProvider>
        <Component {...props} />
      </CompanyContextProvider>
    );
  };

  return MixinComponent;
};

export function useCompany() {
  const context = useContext(CompanyContext);
  return context;
}
