import "../../global.css";
import "./campaignList.css";
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridExcelExportMenuItem,
} from "@mui/x-data-grid-premium";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { ptBR as ptBRCore } from "@mui/x-data-grid/locales";
import { ptBR } from "@mui/x-data-grid/locales";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { userRequest } from "../../requestMethods";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { formatTimeDDMMYYY } from "../../util/helper";
import { DeleteOutline } from "@mui/icons-material";
import { useCompany } from "../../components/context/CompanyContext";
import Swal from "sweetalert2";
import { titleSetRef } from "../../redux/titleRedux";
import { useDispatch, useSelector } from "react-redux";
import Switch from "@mui/material/Switch";

export default function CampaignList() {
  const [campaigns, setCampaigns] = useState([{}]);
  const { companyId } = useCompany();
  const currentUser = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(titleSetRef({ title: "Campanha" }));
    if (companyId > 0) {
      getCampaigns();
    }
  }, [companyId]);

  const getCampaigns = async () => {
    try {
      const res = await userRequest.get(`${companyId}/campaign`);

      setCampaigns(
        res.data.map((item) => ({
          campaignId: item.campaignId,
          title: item.title,
          subTitle: item.subTitle,
          products: item.products.length,
          expiryDate: item.expiryDate,
          createdAt: item.createdAt,
          updatedAt: item.updatedAt,
        }))
      );
    } catch (e) {
      console.error(e);
    }
  };
  const onButtonEditarClick = (e, row) => {
    nav(`${row}`);
  };
  const deleteCp = async (cp, del) => {
    let delToogle = { deleted: null };
    if (del) {
      delToogle = { deleted: new Date(), lastUserUpdate: currentUser.userId };
    }

    const res = await userRequest.delete(`${companyId}/campaign/${cp}`);

    if (res.data > 0) {
      getCampaigns();
    } else {
      Swal.fire("Atenção!", "Erro ao inativar campanha!", "error");
    }
  };
  const onButtonDeleteClick = (e, row) => {
    if (currentUser.isAdmin) {
      if (!e.target.checked) {
        Swal.fire({
          title: "Tem certeza que deseja excluir?",
          text: "Você não poderá reverter isso!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#EE8253",
          confirmButtonText: "Sim, excluir!",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            deleteCp(row, true);
          } else {
            getCampaigns();
          }
        });
      } else {
        deleteCp(row, false);
      }
    } else {
      Swal.fire(
        "Atenção!",
        "Você não tem permissão para inativar usuário!",
        "warning"
      );
    }
  };
  const columns = [
    {
      field: "campaignId",
      headerName: "ID Camapanha",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      hide: false,
    },
    {
      field: "action",
      headerName: "",
      width: 80,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={(e) => onButtonEditarClick(e, params.row.campaignId)}
            >
              <EditIcon />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "title",
      align: "left",
      headerName: "Campanha",
      width: 200,
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "products",
      headerName: "Qtde Produtos",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "expiryDate",
      headerName: "Expira em",
      width: 150,
      align: "left",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
    {
      field: "createdAt",
      headerName: "Criada em",
      width: 150,
      align: "left",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
    {
      field: "updatedAt",
      headerName: "Atualizada em",
      width: 150,
      align: "left",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
    {
      field: "deletar",
      headerName: "",
      width: 80,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <IconButton
            onClick={(e) => onButtonDeleteClick(e, params.row.campaignId)}
          >
            <DeleteOutline />
          </IconButton>
        );
      },
    },
  ];

  const nav = useNavigate();
  const addRow = () => {
    nav(`new`);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const GridToolbarExport = ({
    csvOptions,
    printOptions,
    excelOptions,
    ...other
  }) => (
    <GridToolbarExportContainer {...other}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridExcelExportMenuItem options={excelOptions} />
    </GridToolbarExportContainer>
  );
  return (
    <div className="div_custom_Container">
      <div className="div_custom_UpdateList">
        <div className="div_custom_UpdateRight">
          <Button
            className="custom_add_top"
            variant="outlined"
            color="secondary"
            startIcon={<AddIcon />}
            onClick={addRow}
          >
            Adicionar Campanha
          </Button>
        </div>
        <div className="div_custom_UpdateLeft">
          <div style={{ height: 900 }}>
            <DataGridPremium
              initialState={{
                sorting: {
                  sortModel: [{ field: "expiryDate", sort: "desc" }],
                },
              }}
              sx={{
                fontFamily: "Manrope",
                fontWeight: "500",
                border: "0px",
              }}
              rowHeight={45}
              localeText={
                ptBRCore.components.MuiDataGrid.defaultProps.localeText
              }
              slots={{ toolbar: CustomToolbar }}
              slotProps={{
                panel: {
                  sx: {
                    top: "15px !important",
                    left: "50px !important",
                    position: "fixed !important",
                  },
                },
              }}
              getRowId={(row) => row.campaignId + row.title}
              rows={campaigns}
              disableSelectionOnClick
              columns={columns}
              pagination
            />
          </div>
        </div>
      </div>
    </div>
  );
}
