import Button from "@mui/material/Button";
import { useRef, useState } from "react";
import { generateFileUrl, userRequest } from "../../requestMethods";
import { useCompany } from "../../components/context/CompanyContext";

export function CategoryImage({
  file,
  setFile,
  isEdit,
  setIsEdit,
  categoryId,
}) {
  const inputRef = useRef();
  const { companyId } = useCompany();
  const handleOnclick = (event) => {
    event.preventDefault();
    inputRef.current.click();
  };

  const handleOnChange = (event) => {
    const newFile = event.target.files[0];
    if (!newFile) {
      return;
    }
    setFile(event.target.files[0]);
    event.target.value = "";
  };

  const handleOnDelete = (event) => {
    setFile(undefined);
    setIsEdit(false);
    isEdit(false);
    const res = userRequest.delete(
      `${companyId}/products/categories/deleteImage/${categoryId}`
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "2.3em",
        marginBottom: "2.3em",
      }}
    >
      {file && (
        <div>
          <img
            className="bannerUpload"
            src={
              file && file.name
                ? URL.createObjectURL(file)
                : generateFileUrl(file)
            }
          />
        </div>
      )}

      <div>
        <input
          type="file"
          onChange={handleOnChange}
          hidden
          accept="image/png, image/jpeg"
          ref={inputRef}
        />
        <Button
          variant="outlined"
          className="custom_mui_action"
          onClick={handleOnclick}
        >
          {!file
            ? "Selecione a imagem da categoria"
            : "Escolher outra imagem para categoria"}
        </Button>

        {file && (
          <Button
            color="error"
            variant="outlined"
            className="custom_mui_action"
            onClick={handleOnDelete}
          >
            Remover imagem
          </Button>
        )}
      </div>
    </div>
  );
}
