import "../../global.css";
import "./itens.css";
import { ptBR as ptBRCore } from "@mui/x-data-grid/locales";
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridCellEditStopParams,
  GridCellEditStopReasons,
  MuiEvent,
  GridCellParams,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridExcelExportMenuItem,
  gridClasses,
} from "@mui/x-data-grid-premium";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import Box from "@mui/material/Box";
import * as React from "react";
import { useState, useEffect } from "react";
import { userRequest } from "../../requestMethods";
import { useNavigate } from "react-router-dom";
import { formatPricing } from "../../util/helper";
import Button from "@mui/material/Button";
import { formatTime24hourFormat } from "../../util/helper";
import { useCompany } from "../../components/context/CompanyContext";
import Loading from "../../components/loading/loading";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { titleSetRef } from "../../redux/titleRedux";

export default function OrderList() {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [data, setData] = useState([]);
  const [st, setStatus] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [orderId, setOrderId] = useState(false);
  const { companyId } = useCompany();
  const dispatch = useDispatch();
  const status = [
    "Pendente",
    "Confirmado",
    "Preparado",
    "Pago",
    "Enviado",
    "Finalizado",
    "Cancelado",
  ];

  useEffect(() => {
    dispatch(titleSetRef({ title: "Pedidos" }));
    if (companyId > 0) {
      getOrders();
    }
  }, [companyId]);

  const onButtonEditarClick = (e, row) => {
    nav(`${row}`);
  };

  const getOrders = async () => {
    setIsLoading(true);
    try {
      let obj = {};
      const res = await userRequest.post(`${companyId}/orders/find`, obj);

      const pedido = res.data.map((item) => ({
        orderId: item.orderId,
        orderRef: item.orderRef,
        amount: item.amount,
        status: item.status,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        quantity: item.quantity,
        uniqueId: item.uniqueId,
        name: item.customer.name,
        contact: item.customer.contact,
        rzSocial: item.customer.rzSocial,
        cnpj_cpf: item.customer.cnpj_cpf,
        managedBy: item.managedBy,
        owner_cpf: item.customer.owner_cpf,
        paymentTypeId: item.paymentTypeId,
        paymentAt: item.paymentAt,
        legacy: item.legacy ? "Sim" : "",
      }));

      setData(pedido);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const nav = useNavigate();

  const useFakeMutation = () => {
    return React.useCallback(
      (obj, st) =>
        new Promise(async (resolve, reject) => {
          if (currentUser.isAdmin) {
            const res = await userRequest.put(
              `${companyId}/orders/${obj.orderId}`,
              {
                status: st,
                orderId: obj.orderId,
                lastUserUpdate: currentUser.userId,
              }
            );

            if (Object.keys(res.data).length > 0) {
              resolve(obj);
            } else {
              reject(new Error("Erro atualizar status"));
            }
          } else {
            Swal.fire(
              "Atenção!",
              "Você não tem permissão para editar o status!",
              "warning"
            );
          }
        }),
      []
    );
  };
  const mutateRow = useFakeMutation();
  const AlterarStatus = React.useCallback(async (obj) => {
    let st = "";

    // eslint-disable-next-line default-case
    switch (obj.status) {
      case "Pendente":
        st = "Pendente";
        break;
      case "Confirmado":
        st = "Confirmado";
        break;
      case "Pago":
        st = "Pago";
        break;
      case "Enviado":
        st = "Enviado";
        break;
      case "Preparado":
        st = "Preparado";
        break;
      case "Cancelado":
        st = "Cancelado";
        break;
      case "Finalizado":
        st = "Finalizado";
        break;
    }
    const res = await mutateRow(obj, st);
    return obj;
  }, []);
  const handleProcessRowUpdateError = React.useCallback((error) => {
    console.log("error", error);
  }, []);

  const onButtonDeleteClick = (e, row) => {
    if (currentUser.isAdmin) {
      Swal.fire({
        title: "Tem certeza que deseja Cancelar esse pedido?",
        text: "Você não poderá reverter isso!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#EE8253",
        confirmButtonText: "Sim, canelar!",
        cancelButtonColor: "#d33",
        cancelButtonText: "Não",
      }).then(async (result) => {
        if (result.isConfirmed) {
          AlterarStatus({ status: "Cancelado", orderId: row });
        }
      });
    } else {
      Swal.fire(
        "Atenção!",
        "Você não tem permissão para excluir o produto!",
        "warning"
      );
    }
  };
  const columns = [
    {
      field: "orderRef",
      headerName: "Número Pedido",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "name",
      headerName: "Nome",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "owner_cpf",
      headerName: "CPF proprietário",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "name",
      headerName: "Nome",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "contact",
      headerName: "Contato",
      width: 120,
      align: "left",
      headerAlign: "left",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "rzSocial",
      headerName: "Razão Social",
      width: 200,
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "quantity",
      headerName: "Qtde Itens",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "amount",
      headerName: "Valor",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatPricing(value)}`;
      },
    },
    {
      field: "managedBy",
      headerName: "Vendedor(a)",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      editable: true,
      type: "singleSelect",
      valueOptions: status,
    },
    {
      field: "paymentTypeId",
      headerName: "Pago no App",
      width: 100,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      // valueGetter: (value) => value && new Date(value),
      // valueFormatter: (value) => {
      //   return `${formatTime24hourFormat(value)}`;
      // },
      //type: "date",
      renderCell: (params) => {
        if (params.value === 1) {
          return (
            <>
              <CheckCircleOutlineOutlinedIcon color="success" />
            </>
          );
        } else {
          return "";
        }
      },
    },
    {
      field: "createdAt",
      headerName: "Criado",
      width: 200,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueGetter: (value) => value && new Date(value),
      valueFormatter: (value) => {
        return `${formatTime24hourFormat(value)}`;
      },
      type: "date",
    },
    {
      field: "updatedAt",
      headerName: "Atualizado",
      width: 200,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueGetter: (value) => value && new Date(value),
      valueFormatter: (value) => {
        return `${formatTime24hourFormat(value)}`;
      },
      type: "date",
    },
    {
      field: "legacy",
      headerName: "Integração",
      width: 90,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "itens",
      headerName: "Itens",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="text"
              size="small"
              onClick={(e) => onButtonEditarClick(e, params.row.orderId)}
            >
              Detalhes
            </Button>
          </>
        );
      },
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const GridToolbarExport = ({
    csvOptions,
    printOptions,
    excelOptions,
    ...other
  }) => (
    <GridToolbarExportContainer {...other}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridExcelExportMenuItem options={excelOptions} />
    </GridToolbarExportContainer>
  );
  return (
    <div className="div_custom_">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="div_custom_Container">
          <div className="div_custom_UpdateList">
            <div className="div_custom_UpdateLeft">
              <Box
                sx={{
                  height: 900,
                  [`.${gridClasses.cell}.status_color_criado`]: {
                    background: "#f4f1d5",
                    color: "#a29307",
                  },
                  [`.${gridClasses.cell}.status_color_pendente`]: {
                    background: "#f1d1c3",
                    color: "#863c1c",
                  },
                  [`.${gridClasses.cell}.status_color_confirmado`]: {
                    background: "#f7e0f3",
                    color: "#a3298f",
                  },
                  [`.${gridClasses.cell}.status_color_preparado`]: {
                    background: "#bdc2e9",
                    color: "#040a3e",
                  },
                  [`.${gridClasses.cell}.status_color_pago`]: {
                    background: "#c2e0f4",
                    color: "#0e67a3",
                  },
                  [`.${gridClasses.cell}.status_color_enviado`]: {
                    background: "#e7ccf9",
                    color: "#9815ef",
                  },
                  [`.${gridClasses.cell}.status_color_finalizado`]: {
                    background: "#c7f6da",
                    color: "#06632b",
                  },
                  [`.${gridClasses.cell}.status_color_cancelado`]: {
                    background: "rgb(243, 142, 142)",
                    color: "red",
                  },
                }}
              >
                <DataGridPremium
                  sx={{
                    fontFamily: "Manrope",
                    fontWeight: "500",
                    border: "0px",
                  }}
                  localeText={
                    ptBRCore.components.MuiDataGrid.defaultProps.localeText
                  }
                  slots={{ toolbar: CustomToolbar }}
                  slotProps={{
                    panel: {
                      sx: {
                        top: "15px !important",
                        left: "50px !important",
                        position: "fixed !important",
                      },
                    },
                  }}
                  rowHeight={45}
                  getRowId={(row) => row.orderId}
                  rows={data}
                  disableSelectionOnClick
                  columns={columns}
                  getCellClassName={(params) => {
                    return params.value === "Criado"
                      ? "status_color_criado"
                      : params.value === "Confirmado"
                      ? "status_color_confirmado"
                      : params.value === "Pendente"
                      ? "status_color_pendente"
                      : params.value === "Preparado"
                      ? "status_color_preparado"
                      : params.value === "Pago"
                      ? "status_color_pago"
                      : params.value === "Enviado"
                      ? "status_color_enviado"
                      : params.value === "Finalizado"
                      ? "status_color_finalizado"
                      : params.value === "Cancelado"
                      ? "status_color_cancelado"
                      : "";
                  }}
                  pagination
                  autoPageSize
                  processRowUpdate={AlterarStatus}
                  onProcessRowUpdateError={handleProcessRowUpdateError}
                />
              </Box>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
