import { DeleteOutline } from "@mui/icons-material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import DiscountIcon from "@mui/icons-material/Discount";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import IconButton from "@mui/material/IconButton";
import {
  DataGridPremium,
  GridFilterPanel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  useGridApiRef,
  GridExcelExportMenuItem,
} from "@mui/x-data-grid-premium";
import { ptBR as ptBRCore } from "@mui/x-data-grid/locales";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useCompany } from "../../components/context/CompanyContext";
import Loading from "../../components/loading/loading";
import "../../global.css";
import { userRequest } from "../../requestMethods";
import { formatPricing, formatTimeDDMMYYY } from "../../util/helper";
import "./productList.css";
import Avatar from "@mui/material/Avatar";
import { generateFileUrl } from "../../requestMethods";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { titleSetRef } from "../../redux/titleRedux";
import { setState } from "../../redux/dataGridProductRedux";

export default function ProductList() {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [isLoading, setIsLoading] = useState(false);
  const { companyId } = useCompany();
  const [data, setData] = useState();
  const dispatch = useDispatch();
  const dataGridState = useSelector(
    (state) => state.dataGridProductPremium.stateGrid
  );
  const apiRef = useGridApiRef();

  function filterModelChanged() {
    dispatch(
      setState({
        initialState: apiRef.current.exportState(),
      })
    );
  }

  useEffect(() => {
    dispatch(titleSetRef({ title: "Produtos" }));
    if (companyId > 0) {
      getProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);
  const getProducts = async () => {
    try {
      setIsLoading(true);
      const res = await userRequest.get(`${companyId}/products/admin`);
      setData(res.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };
  const onButtonEditarClick = (e, row) => {
    nav(`${row}`);
  };

  const deletePrd = async (prd) => {
    const res = await userRequest.delete(`${companyId}/products/${prd}`);

    if (res.data > 0) {
      getProducts();
    } else {
      Swal.fire("Atenção!", "Erro ao excluir produto!", "error");
    }
  };
  const handleChangePremium = async (e, row) => {
    // await userRequest.put(`${companyId}/products/${row}`, {
    //   presales: e.target.checked,
    // });
  };

  const onButtonDeleteClick = (e, row) => {
    if (currentUser.isAdmin) {
      Swal.fire({
        title: "Tem certeza que deseja excluir?",
        text: "Você não poderá reverter isso!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#EE8253",
        confirmButtonText: "Sim, excluir!",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          deletePrd(row);
        }
      });
    } else {
      Swal.fire(
        "Atenção!",
        "Você não tem permissão para excluir o produto!",
        "warning"
      );
    }
  };

  const columns = [
    {
      field: "avatar",
      headerName: "Foto",
      headerClassName: "dataGridMuiHeader",
      align: "center",
      headerAlign: "center",
      width: 50,
      display: "flex",
      renderCell: (params) => {
        return (
          <>
            <Avatar src={generateFileUrl(params.value)} />
          </>
        );
      },
    },
    {
      field: "presales",
      headerName: "Pré-venda",
      width: 100,
      align: "center",
      headerClassName: "dataGridMuiHeader",
      headerAlign: "center",
      display: "flex",
      renderCell: (params) => {
        const date = new Date();
        return (
          <input
            className="star"
            type="checkbox"
            name="presales"
            checked={params.value ? true : false}
            //onChange={(e) => handleChangePremium(e, params.row.productId)}
          />
        );
      },
    },
    {
      field: "action",
      headerName: "",
      width: 50,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      renderCell: (params) => {
        if (currentUser.isAdmin) {
          return (
            <>
              <IconButton
                onClick={(e) => onButtonEditarClick(e, params.row.productId)}
              >
                <EditIcon />
              </IconButton>
            </>
          );
        }
      },
    },
    {
      field: "enabled",
      headerName: "Visível",
      width: 80,
      align: "center",
      headerAlign: "center",
      type: "boolean",
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      renderCell: (params) => {
        if (params.value) {
          return <VisibilityOutlinedIcon fontSize="small" color="success" />;
        } else {
          return (
            <VisibilityOffOutlinedIcon fontSize="small" sx={{ color: "red" }} />
          );
        }
      },
    },
    {
      field: "status",
      headerName: "Status Produto",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      description:
        "S = todas numeração tem em estoque e tem foto, P = alguma numeração não tem em estoque e tem foto, N = não tem estoque ou não tem foto",
      display: "flex",
      renderCell: (params) => {
        // eslint-disable-next-line default-case
        switch (params.value) {
          case "S":
            return (
              <>
                <CheckCircleOutlineOutlinedIcon color="success" />
              </>
            );
          case "N":
            return (
              <>
                <RemoveCircleOutlineOutlinedIcon sx={{ color: "red" }} />
              </>
            );
          case "P":
            return (
              <>
                <ReportGmailerrorredIcon sx={{ color: "#EE8253" }} />
              </>
            );
        }
      },
    },
    {
      field: "ref",
      headerName: "Referência",
      width: 80,
      align: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "title",
      align: "left",
      headerName: "Produto",
      width: 250,
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "category",
      headerName: "Categoria",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "subCategory",
      headerName: "Sub Categoria",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "discount",
      headerName: "Preço",
      width: 100,
      align: "center",
      headerAlign: "center",
      type: "number",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatPricing(value)}`;
      },
    },
    {
      field: "percentDiscount",
      headerName: "% Desconto",
      width: 120,
      align: "center",
      headerAlign: "center",
      type: "number",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "price",
      headerName: "Preço com Desconto",
      width: 160,
      align: "center",
      headerAlign: "center",
      type: "number",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatPricing(value)}`;
      },
    },
    {
      field: "promo",
      headerName: "Promocional",
      width: 130,
      align: "center",
      headerAlign: "center",
      type: "boolean",
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      renderCell: (params) => {
        return params.value ? <DiscountIcon color="success" /> : false;
      },
    },
    {
      field: "countImages",
      headerName: "Qtde de Fotos",
      width: 100,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      type: "number",
    },
    {
      field: "stock",
      headerName: "Estoque",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      description:
        "S = todas numeração tem em estoque, P = alguma numeração não tem em estoque, N = não tem estoque",
      display: "flex",
      renderCell: (params) => {
        // eslint-disable-next-line default-case
        switch (params.value) {
          case "S":
            return (
              <>
                <CheckCircleOutlineOutlinedIcon color="success" />
              </>
            );
          case "N":
            return (
              <>
                <RemoveCircleOutlineOutlinedIcon sx={{ color: "red" }} />
              </>
            );
          case "P":
            return (
              <>
                <ReportGmailerrorredIcon sx={{ color: "#EE8253" }} />
              </>
            );
        }
      },
    },
    {
      field: "qtdeStock",
      headerName: "Qtde Produtod",
      width: 100,
      align: "center",
      headerAlign: "center",
      type: "number",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "createdAt",
      headerName: "Criada em",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
    {
      field: "updatedAt",
      headerName: "Atualizada em",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
    {
      field: "visibleAt",
      headerName: "Visível em",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
    {
      field: "updateImages",
      headerName: "Foto atualizada em",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
    {
      field: "delete",
      headerName: "",
      width: 80,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      renderCell: (params) => {
        if (currentUser.isAdmin) {
          return (
            <IconButton
              onClick={(e) => onButtonDeleteClick(e, params.row.productId)}
              variant="contained"
            >
              <DeleteOutline />
            </IconButton>
          );
        }
      },
    },
  ];

  const nav = useNavigate();
  const addRow = () => {
    nav(`new`);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const GridToolbarExport = ({
    csvOptions,
    printOptions,
    excelOptions,
    ...other
  }) => (
    <GridToolbarExportContainer {...other}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridExcelExportMenuItem options={excelOptions} />
    </GridToolbarExportContainer>
  );

  return (
    <div className="div_custom_">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="div_custom_Container">
          <div className="div_custom_UpdateList">
            <div className="div_custom_UpdateRight">
              <Button
                className="custom_add_top"
                variant="outlined"
                color="secondary"
                startIcon={<AddIcon />}
                onClick={addRow}
              >
                Adicionar Produto
              </Button>
            </div>
            <div className="div_custom_UpdateLeft">
              <div style={{ height: 900 }}>
                {data ? (
                  <DataGridPremium
                    sx={{
                      fontFamily: "Manrope",
                      fontWeight: "500",
                      border: "0px",
                    }}
                    rowHeight={45}
                    initialState={dataGridState.initialState}
                    onFilterModelChange={filterModelChanged}
                    apiRef={apiRef}
                    filterModelChanged
                    localeText={
                      ptBRCore.components.MuiDataGrid.defaultProps.localeText
                    }
                    slots={{ toolbar: CustomToolbar }}
                    slotProps={{
                      panel: {
                        sx: {
                          top: "15px !important",
                          left: "50px !important",
                          position: "fixed !important",
                        },
                      },
                    }}
                    getRowId={(row) => row.productId}
                    rows={data}
                    disableSelectionOnClick
                    columns={columns}
                    pagination
                  />
                ) : (
                  false
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
